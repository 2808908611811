@use "../../scss" as *;

.Layout {
  font-family: $font-family-1;
  background-color: #fcfcfc;
}

.Header {
  background-color: $tertiary-color;

  &::after {
    content: "";
    background: linear-gradient(
                    180deg,
                    rgba(9, 30, 66, 0.13) 0%,
                    rgba(9, 30, 66, 0.13) 25%,
                    rgba(9, 30, 66, 0.08) 25.01%,
                    rgba(9, 30, 66, 0) 100%
    );
    width: 100%;
    height: 0.25rem;
    margin-top: -0.25rem;
    position: absolute;
  }

  .wrapper {
    display: grid;
    grid-template-columns: min-content 3fr 1fr;
    place-items: center;
    padding: 1rem 1.5rem;

    @media all and (max-width: $screen-md) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media all and (max-width: $screen-sm) {
      grid-template-columns: 1fr;
    }

    .toolbar {
      position: relative;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          display: inline;
          padding: 0.5rem 0.75rem;
          cursor: pointer;
          font-size: 1.125rem;
          font-weight: $font-black;
          color: #344563;
          border-radius: 0.25rem;

          &:hover {
            background: rgba(9, 30, 66, 0.08);
          }

          .active {
            border-bottom: 0.25rem solid $primary_color;
            padding-bottom: 0.25rem;
          }
        }

        .contactus {
          &:hover {
            background: inherit;
          }
        }

        .dropdown {
          padding-right: 0;

          svg {
            color: #344563;
          }

          &::after {
            content: "";
            width: 15%;
            height: 10px;
            position: absolute;
            bottom: 0;
            left: 0;
          }

          &:hover {
            background: inherit;
          }

          &:hover > .dropdown-content {
            visibility: visible;
            animation: showDropdown 0.05s;
          }

          .dropdown-content {
            list-style: none;
            position: absolute;
            background: #ffffff;
            border-radius: 3px;
            box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
            visibility: hidden;
            z-index: 10;

            li {
              display: block;
              font-weight: $font-bold;
              font-size: 1rem;
              padding: 0.5rem 1rem;
              margin: 0.25rem 0;
              border-radius: 0;

              &:hover {
                background: rgba(9, 30, 66, 0.08);
              }
            }
          }
        }
      }
    }

    .profile-toolbar {
      justify-self: end;

      ul {
        list-style: none;

        li {
          display: inline;
          padding: calc($rem-size / 2);
          cursor: pointer;
        }
      }
    }
  }
}

.MobileHeader {
  background-color: $tertiary-color;
  padding: 0.5rem 0;

  &::after {
    content: "";
    background: linear-gradient(
                    180deg,
                    rgba(9, 30, 66, 0.13) 0%,
                    rgba(9, 30, 66, 0.13) 25%,
                    rgba(9, 30, 66, 0.08) 25.01%,
                    rgba(9, 30, 66, 0) 100%
    );
    width: 100%;
    height: 0.25rem;
    margin-top: -0.25rem;
    position: absolute;
  }

  .wrapper {
    display: grid;
    grid-template-columns: min-content min-content 1fr;
    grid-gap: 0.25rem;
    place-items: center;
    padding: 0.5rem 0;

    .menu {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 75%;
        left: 0;
        z-index: 10;
      }

      &:hover > .dropdown-content {
        visibility: visible;
      }

      .dropdown-content {
        left: 0;
        top: 3rem;
        z-index: 1000;
        list-style: none;
        position: absolute;
        border-radius: 3px;
        width: max-content;
        visibility: hidden;
        background: #ffffff;
        box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);

        li {
          font-size: 1rem;
          padding: 0.5rem 1rem;
          margin: 0.25rem 0;
          border-radius: 0;

          &:hover {
            background: rgba(9, 30, 66, 0.08);
          }
        }
      }
    }

    .profile-toolbar {
      justify-self: end;

      ul {
        list-style: none;

        li {
          display: inline;
          padding: calc($rem-size / 2);
          cursor: pointer;
        }
      }
    }
  }
}

.Footer {
  background-color: $primary-color;
  color: $tertiary-color;
  margin-top: 50px;

  .wrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1rem;
    margin: 0;
    padding: 1.5rem;

    .item-1,
    .item-2,
    .item-3,
    .item-4,
    .item-5 {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: calc(3.5 * $rem-size);
        font-weight: $font-medium;

        li {
          padding: $font-size-1 - $font-size-diff - 0.125rem;
        }
      }
    }

    @media all and (max-width: $screen-md) {
      grid-template-columns: 1fr 1fr;
    }
    @media all and (max-width: $screen-sm) {
      grid-template-columns: 1fr;
    }
  }

  .title {
    font-weight: $font-semibold;
    font-size: clac(5 * $rem-size);
    word-wrap: break-word;
  }

  .social-icons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.5rem;
    margin: 0.25rem;
    margin-left: 0;
    padding: 1rem !important;
    cursor: pointer;


    img {
      background-color: white;
      border-radius: 81px;
      height: 30px;
      width: 30px;
    }

    @media all and (max-width: $screen-sm) {
      margin: 1.5rem !important;
    }
  }
}

@keyframes showDropdown {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}
