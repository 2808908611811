@use "../../../scss" as *;

.courseDetailNavbar {
  margin-top: -3px;

  ul {
    list-style: none;
    text-align: center;

    li {
      display: inline-block;
      padding: 0.5rem 1rem;
      color: var(--h_clr_500);
      cursor: pointer;
      transition: box-shadow 100ms ease-in;

      @media all and (max-width: $screen-md) {
        padding: 0.5rem;
      }
    }
  }
  .academic {
    li {
      &:hover {
        background-color: #fbeaeb;
        box-shadow: 0px 4px 0px 0px $secondary-color;
      }
    }
  }
  .nonAcademic {
    li {
      &:hover {
        background-color: #c0dcee;
        box-shadow: 0px 4px 0px 0px $primary-color;
      }
    }
  }
}

.courseDetailWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  align-items: center;
  margin: var(--section_margin);

  div:nth-child(2) {
    .medium {
      color: #3c4852;
      background-color: #e9eef2;
      padding: 0.25rem 0.5rem;
      border-radius: 0.5rem;
      width: max-content;
    }
    .priceTag {
      @include customFlex($justify-content: flex-start);
      padding: 0.5rem 0;

      div:nth-child(1),
      div:nth-child(2) {
        padding: 0 0.5rem;
      }

      .sellingPrice {
        color: var(--h_clr_500);
      }
      .discount {
        text-decoration: line-through;
        color: $secondary-color;
      }
    }
    .valid {
      color: $secondary-color;
    }
    .instructor {
      ul {
        list-style: none;

        li {
          margin: 0.25rem;
          padding: 0.5rem;
          display: inline-flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-around;
          color: var(--h_clr_500);

          &:first-child {
            padding-left: 0;
          }

          div:nth-child(1),
          div:nth-child(2) {
            padding: 0 0.5rem;
          }
        }
      }
    }
    .buy {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: var(--grid_gap);
      button {
        font-size: 1.25rem;
      }
    }
    .extra {
      padding: 1rem 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: var(--grid_gap);

      .item {
        @include customFlex($justify-content: flex-start);
        cursor: default;
        color: #3c4852;

        div:nth-child(1),
        div:nth-child(2) {
          padding: 0 0.5rem;
        }
      }
    }
  }

  @media all and (max-width: $screen-md) {
    grid-template-columns: 1fr;
  }
}

.aboutCourse,
.curriculumnSection,
.courseFacultySection,
.courseFacultySection,
.demoVideoSection {
  h2 {
    margin: var(--section_margin);
    color: var(--h_clr_500);
  }
}

.courseFacultySection {
  h2 {
    text-align: center;
  }
}

/*
  Curriculumn
*/

.curriculumnSection {
  .curriculumn_header {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 1rem;
    max-width: 100vw;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0.001rem;
    }
  }
}

.curriculumn {
  margin: var(--section_margin);
  background: #e6f5fe;
  border-radius: 12px;
  padding: 1rem;

  .curriculumn_wrapper {
    margin: 0.5rem auto;

    .curriculumn_section_header {
      @include customFlex();
      padding: 0 1rem;
      cursor: pointer;

      .arrow {
        transition: transform 10ms ease-in-out;
        transform: rotate(180deg);
      }
    }

    .curriculumn_section {
      list-style: none;
      transition: all 3ms ease-in-out;

      .curriculumn_sub_section {
        background-color: #ffffff;
        margin: 0.25rem 0;
        padding: 0.25rem;

        @include customFlex($justify-content: flex-start);

        div:nth-child(1),
        div:nth-child(2) {
          margin: 0 0.25rem;
        }
        svg {
          margin-top: 0.25rem;
        }
        // img {
        //   width: 1rem;
        //   margin-top: 0.25rem;
        // }
      }
    }
  }

  .close {
    .curriculumn_section_header {
      background-color: #ffffff;
      cursor: pointer;

      .arrow {
        transform: rotate(0);
      }
    }

    .curriculumn_section {
      visibility: hidden;
      height: 0;
    }
  }
}

.mediaCard {
  width: max-content;
  background: #ffffff;
  padding: 0.125rem 0.5rem;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.07);

  h5 {
    color: #544f77;
  }
  span {
    color: $primary-color;
    padding: 0 0.125rem;
  }

  .wrapper {
    @include customFlex($justify-content: space-between);

    div:nth-child(1) {
      margin-right: 1rem;
    }
    div:nth-child(2) {
      margin-left: 1rem;
    }

    img {
      max-width: 2rem;
    }
  }
}

/*
  Demo Videos
*/

.demoVideoSection {
  --bg_color: #fbeaeb;

  background-color: var(--bg_color);
  box-shadow: var(--bg_color) 0 0 0 100vmax;
  clip-path: inset(0 -100vmax);
  padding: 1rem;

  .content {
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;

      @media all and (max-width: $screen-md) {
        grid-template-columns: 1fr;
      }
    }
    .lecture_video {
      margin-top: 1rem;

      p {
        margin: 0.5rem 0;
      }
    }
    .demo_playlist {
      p {
        margin: 0.5rem 0;
      }
      .wrapper {
        max-height: 45vh;
        overflow: auto;
        @include customScrollbar();
        cursor: pointer;

        .item {
          background-color: #ffffff;
          color: var(--h_clr_500);
          margin: 0.75rem;
          padding: 0.75rem;
          border-radius: 6.77966px;
          opacity: 0.5;

          span {
            display: block;
            color: $secondary-color;
          }
        }
        .active {
          opacity: 1;
          box-shadow: 0px 67.7966px 67.7966px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
