@use "../../scss" as *;

.cartCard {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 1rem;
  align-items: center;
  justify-items: center;
  margin: var(--section_margin);

  @media all and (max-width: $screen-md) {
    grid-template-columns: 1fr;
  }

  .productDetail {
    .productHeader {
      @include customFlex($justify-content: space-around);
      background: #ffffff;
      border-radius: 0.5rem;
      color: $primary-color;
      text-transform: uppercase;
      padding: 1rem;
    }
    .productCard {
      display: grid;
      grid-template-columns: 1fr 3fr 1fr 1fr;
      align-items: center;
      margin: var(--section_margin);

      & > div:nth-child(2) {
        margin-left: 1rem;
      }

      .addons {
        @include customFlex();
        color: #3c4852;

        .wrapper {
          margin-right: 0.25rem;
          @include customFlex($justify-content: flex-start);

          svg {
            color: $primary-color;
            padding-right: 0.25rem;
          }
        }
      }
      .price {
        color: $primary-color;
        justify-self: center;

        @media all and (max-width: $screen-md) {
          padding: 1rem;
        }
      }
      .closeIcon {
        justify-self: end;
        color: $secondary-color;

        @media all and (max-width: $screen-md) {
          justify-self: center;
        }
      }
    }
  }
  .cartTotal {
    padding: 1rem 1.5rem;
    background: #ffffff;
    box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.07);
    width: 70%;
    justify-self: end;

    @media all and (max-width: $screen-md) {
      justify-self: center;
    }

    .total {
      .wrapper {
        @include customFlex();

        div:nth-child(1) {
          padding: 0.5rem 0;
          color: #072f60;
        }
        div:nth-child(2) {
          padding: 0.5rem 0;
          color: $primary-color;
        }
      }
    }
    button {
      width: 100%;
      font-weight: $font-bold;
      line-height: 200%;
      letter-spacing: 1px;
    }
  }
}

.couponWrapper {
  margin: 1rem;
  padding: 1rem;
}


.nothing-cardt{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
   gap: 1rem;
  p{
    text-align: center;
    color: $primary-color;
    font-size: 1.5rem;
  }
}