$primary-color: #0474ba;
$secondary-color: #C4262F;
$tertiary-color: #ffffff;
$highlight-color: #FCB03E;
$fifth-color: #156C3C;

$p-color: #696984;

$font-size-1: 1rem;
$font-size-diff: 0.25rem;
$font-xl: 2rem;
$font-lg: 1.5rem;
$font-md: 1rem;
$font-sm: 0.75rem;
$rem-size: 0.25rem;


$font-black: 900;
$font-bold: 700;
$font-semibold: 600;
$font-medium: 500;
$font-regular: 400;
$font-light: 300;
$font-thin: 100;

$font-family-1: "Lato", sans-serif;
$font-family-2: "Roboto", sans-serif;

$screen-xs: 0px;
$screen-sm: 600px;
$screen-md: 900px;
$screen-lg: 1200px;
$screen-xl: 1536px;
