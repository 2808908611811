@use "../../scss" as *;

.checkoutCard {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: calc(2 * var(--grid_gap));
  margin: var(--section_margin);

  @media all and (max-width: $screen-md) {
    grid-template-columns: 1fr;
  }

  .billingCard {
    background: #ffffff;
    box-shadow: 0px 16px 32px rgba(233, 238, 242, 0.4);
    border-radius: 1rem;
    padding: 1rem;
    height: max-content;

    .cardContent {
      width: 80%;
      margin: 0 auto 1rem auto;

      h2 {
        color: var(--h_clr_500);
      }

      .formFields {
        display: flex;
        flex-direction: column;
        grid-gap: 1rem;

        .phoneContainer {
          grid-column: 1/3;
        }

        .phoneInput {
          color: #3c4852;
          width: 100%;

          &:focus,
          &:active,
          &:hover {
            box-shadow: none !important;
            border-color: #c9c9c9;
          }
        }

        input:focus ~ fieldset,
        input:active ~ fieldset,
        input:hover ~ fieldset {
          border-width: 1px;
          border-color: #c9c9c9;
          box-shadow: none !important;
        }

        input:disabled {
          background-color: #e8f1f7;
          border: none;
        }
      }
    }
  }

  .checkoutDetail {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;

    .productDetailsWrapper {
      max-height: 40vh;
      overflow: auto;

      .productDetails {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        grid-gap: 0.5rem;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 16px 32px rgba(232, 241, 247, 0.4);
        border-radius: 0.5rem;
        padding: 0.25rem;
        margin: 0.5rem 0;

        .price {
          align-self: center;
          justify-self: end;
          color: $primary-color;
        }
      }

      &::-webkit-scrollbar {
        width: 0.25rem;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #0373ba;
        border-radius: 0.75rem;
        background-color: #0373ba;
      }

      &::-webkit-scrollbar-thumb {
        background: #ffffff;
        box-shadow: inset 0 0 5px #ffffff;
        border-radius: 0.75rem;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #fffffff3;
      }
    }

    .couponWrapper {
      margin: 1rem 0;
      padding: 1rem 0;
      text-align: start;
    }

    .cartTotal {
      background: #ffffff;
      box-shadow: 0px 16px 32px rgba(233, 238, 242, 0.4);
      border-radius: 0.5rem;
      padding: 1rem;

      .wrapper {
        @include customFlex();

        div:nth-child(1),
        div:nth-child(2) {
          padding: 0.5rem 0;
          color: #696984;
        }
      }

      .total {
        color: var(--h_clr_500);
        font-weight: $font-bold;
      }
    }

    .paymentImage {
      padding: 1rem 0;
    }

    .placeOrder button {
      font-weight: $font-bold;
      width: 100%;
      margin: 0.5rem 0;
    }
  }

  .otpField {
    color: #3c4852;
    grid-column: 1/3;
    margin-top: 1rem;
    width: 100%;
  }
}
