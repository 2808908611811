.course-name{
    font-weight: bold;
    margin-top: 5%;
    margin-bottom: 5%;
    padding-left: 2.5%;
    font-size: 1.3rem;
    text-align: start;
}
.readMore{
    padding-bottom: 5%;
    padding-left: 5%;
}
.course-date{
    color: #444;
}
.course-price{
    font-weight: bold;
}
.course-card-body{
    text-align: start;
}
.checkbox{
    padding-bottom: 5%;
    padding-right: 5%;
}