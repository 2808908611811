@use "../../../scss" as *;

.buttonLink {
  text-decoration: none;
  color: inherit;
}

.TestHeader {
  background-color: $tertiary-color;

  .wrapper {
    display: flex;
    // grid-template-columns: 1fr 1.5fr 1.5fr 1fr;
    justify-content: space-between;
    align-items: center;
    // justify-items: center;
    padding: 0.5rem 1.5rem;
    border-bottom: 1px solid rgba(9, 30, 66, 0.24);
    box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 1px;

    .testName {
      display: inline;
      padding: 0 0.75rem;
      cursor: pointer;
      font-size: 1.125rem;
      font-weight: $font-bold;
      color: #344563;
    }
    .timestamp {
      background-color: #e6f5fe;
      padding: 0.75rem;
      font-size: calc(5 * $rem-size);
      font-weight: $font-bold;
      border-radius: 0.25rem;
      span {
        padding: 0.25rem;
        margin: 0.25rem;
        background-color: $primary-color;
        color: $tertiary-color;
        border-radius: 0.25rem;
      }
    }
    .profile {
      justify-self: end;
    }

    @media all and (max-width: $screen-md) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;

      .logo {
        grid-row: 1/1;
        grid-column: 1/2;
      }
      .profile {
        grid-row: 1/1;
        grid-column: 2/3;
      }
      .testName,
      .timestamp {
        grid-column: 1/3;
      }
    }
  }
}

.TestNavbar {
  padding: 0;
  margin: 0;
  box-shadow: 4px 4px 12px rgba(1, 1, 1, 0.1);
  background-color: $tertiary-color;

  ul {
    list-style: none;
    text-align: start;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin: 0 3vw;
      font-size: 4.25 * $rem-size;
      font-weight: $font-bold;
      padding: 0.75rem 0;
      color: #0a033c;
      text-transform: capitalize;
    }
    .active {
      border-bottom: 0.35rem solid $primary-color;
    }
  }
}
