.main-user-dash{
    width: -webkit-fill-available;
    display: flex;
    // border: 2px solid pink;
    justify-content: center;
    // align-items: center;
    height: 90vh;
    text-align: center;
}
.all-user-strip{
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 2% 0 1.5% 0;
    border-radius: 5px;
    padding-left: 2%;
    // border: 2px solid black;
    height: 3.5rem;
    background: white;
    box-shadow: 4px 4px 20px 8px rgba(0, 0, 0, 0.08);
}
.userGrid{
    // height: calc(100% - 6rem);
    height: 75%;
    // background: white;
    margin-top: 1.5%;
}
.userTable{
    // height: calc(100% - 6rem);
    height: 75%;
    background: white;
    margin-top: 1.5%;
    box-shadow: 4px 4px 20px 8px rgba(0, 0, 0, 0.08);
}
.left-side-profile{
    // border: 2px solid yellow;
    height: -webkit-fill-available;
    padding: 2%;
    width: 90%;
    display: flex;
    flex-direction: column;
}
.right-side-profile{
    // border: 2px solid yellow;
    height: -webkit-fill-available;
    padding: 2%;
    align-items: center;
    width: 90%;
}
.left-details > div{
    margin-top: 5%;
}
.right-details > div{
    margin-top: 5%;
}
.orderitem-1{
    width: 100%;
    // border: 2px solid yellow;
}
.orderdiv1{
    padding: 2.5%;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 4px 4px 20px 8px rgba(0, 0, 0, 0.08);
}
.OrderPaper{
    // height: calc(100% - 6rem);
    // border-radius: 5px;
    height: 100%;
    overflow-y: scroll;
    background: #F1F2F5;
    margin-top: 1.5%;
    // box-shadow: 4px 4px 20px 8px rgba(0, 0, 0, 0.08);
    display: flex;
}
.orderdiv2{
    padding: 2.5%;
    border-radius: 5px;
    border: 1px solid #DDD;
    background: #F8F8F8;
    box-shadow: 4px 4px 20px 8px rgba(0, 0, 0, 0.08);
}
.orderTotal{
    padding: 0% 0% 5% 0;
}

.UserPaper{
    // height: calc(100% - 6rem);
    // border-radius: 5px;
    height: 100%;
    overflow-y: scroll;
    background: #F1F2F5;
    margin-top: 1.5%;
    // box-shadow: 4px 4px 20px 8px rgba(0, 0, 0, 0.08);
    display: flex;
}