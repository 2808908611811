.form{
    display: flex;
    flex-direction: column;
    padding: 1%;
}
.upload{
    margin-top: 5rem;
}
.left-side{
    height: -webkit-fill-available;
    padding: 2%;
    align-items: center;
    width: 100%;
}
.right-side{
    height: -webkit-fill-available;
    width: 100%;
    padding: 2%;
}
.FacPaper{
    // height: calc(100% - 6rem);
    border-radius: 5px;
    height: 100%;
    overflow-y: scroll;
    background: white;
    margin-top: 1.5%;
    box-shadow: 4px 4px 20px 8px rgba(0, 0, 0, 0.08);
    display: flex;
}

@media only screen and (max-width: 900px) {
    .left-side{
        height: max-content;
    }
  }
.main-dash {}