@use "../../scss" as *;

.testsetPerformance {
  h2 {
    margin: var(--section_margin);
  }

  .overall {
    .wrapper {
      text-align: center;
      background: #e6f5fe;
      box-shadow: 4px 4px 12px rgba(1, 1, 1, 0.1);
      display: grid;
      // grid-template-columns: repeat(4, 1fr);
      grid-template-columns: repeat(3, 1fr);
      width: 80%;
      margin: 0 auto;

      .wrapper2 {
        @include customFlex($flex-direction: column);
        padding: 1rem;
        border: 1px solid #0474ba;

        span {
          color: #797171;
        }
      }

      @media all and (max-width: $screen-md) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  .sectionSummary {
    table {
      width: 100%;
      text-align: left;
      border-collapse: collapse;
      background: #ffffff;
      box-shadow: 4px 4px 12px rgba(1, 1, 1, 0.1);

      th,
      td {
        margin: 0;
        padding: 1rem;
        font-weight: $font-bold;
        border-bottom: 1px solid #0474ba;
        border-left: 1px solid #0474ba;
        width: 25%;

        span {
          color: #797171;
        }
      }

      th {
        color: #797171;
      }

      tr {
        td:nth-child(1) {
          border-left: 0;
        }
        th:nth-child(1) {
          border-left: 0;
        }
      }
    }
  }

  .remarks {
    .content {
      height: 412px;
      padding: 0.5rem;
      overflow-y: auto;
      background: #ffffff;
      border: 1px solid #000000;
      font-size: 1rem;
      line-height: 1.5rem;

      &::-webkit-scrollbar {
        width: 0.5rem;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #0373ba;
        border-radius: 0.75rem;
        background-color: #0373ba;
      }

      &::-webkit-scrollbar-thumb {
        background: #ffffff;
        box-shadow: inset 0 0 5px #ffffff;
        border-radius: 0.75rem;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #fffffff3;
      }
    }
  }

  .actionBtn {
    margin: 1rem;
    text-align: end;

    button {
      margin-left: 1rem;
      background-color: #253858;
      font-weight: $font-medium;
      color: #ffffff;
    }
  }
}
