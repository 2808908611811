.detail-head{
    font-weight: bold;
}
.payment-details{
    color: #666;
}
.payment-details > div {
    margin: 2.5%;
    margin-top: 0.5%;
    margin-left: 0;
    width: 100%;
    // border: 2px solid yellow;
}
.item-1{
    // border: 2px solid yellow;
    // padding: 2.5%;
    width: 100%;
}