@use "../../../scss" as *;

.testsetNavbar {
  background: #ffffff;
  box-shadow: 4px 4px 12px rgba(1, 1, 1, 0.1);
  border-radius: 5px 5px 0px 0px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: auto;

    li {
      display: inline-block;
      padding: 1rem 1rem;
      margin: 0 0.5rem;
      color: #666666;
      font-size: 4.25 * $rem-size;
      text-transform: capitalize;
      cursor: pointer;
    }
    .active {
      color: $fifth-color;
      border-bottom: 0.25rem solid $fifth-color;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
