@use "../../scss" as *;

.profileContainer {
  .card {
    width: 100%;
    margin: 1.5rem 0;
    padding: 1rem;
    box-shadow: 0px 16px 32px rgba(233, 238, 242, 0.4);
    border-radius: 0.5rem;
    background-color: $tertiary-color;

    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: center;
    grid-gap: 2rem;

    @media all and (max-width: $screen-md) {
      grid-template-columns: 1fr;
    }

    .profileImage {
      justify-self: center;
      .wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem;
        place-items: center;

        .avatar {
          img {
            width: 100%;
            cursor: pointer;
          }
          input {
            display: none;
          }
        }
      }
    }

    .formGroup {
      form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;

        @media all and (max-width: $screen-md) {
          grid-template-columns: 1fr;
        }

        input:focus ~ fieldset,
        input:active ~ fieldset,
        input:hover ~ fieldset {
          border-width: 1px;
          border-color: #c9c9c9;
          box-shadow: none !important;
        }
        .loginPhone {
          color: #3c4852;
          width: 100%;

          &:focus,
          &:active,
          &:hover {
            box-shadow: none !important;
            border-color: #c9c9c9;
          }
        }
        .phoneContainer {
          align-self: end;
        }
        .special-label {
          color: gray;
        }
      }
    }

    .updateButton {
      grid-area: 4/2;
      justify-self: end;

      @media all and (max-width: $screen-md) {
        grid-area: 7/1;
        justify-self: center;
      }
    }
  }
}
