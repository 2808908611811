@use "../../scss" as *;

.dashboardWrapper {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: var(--grid_gap);

  @media all and (max-width: $screen-md) {
    grid-template-columns: 1fr;
  }

  .dashboardMainContent {
    @include dashboardContent();
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--grid_gap);

    h1 {
      @include customH1();
    }
  }

  .dashboardSideBar {
    .blogSideCollections {
      @include dashboardSidebar();
      .divider {
        background-color: $primary-color;
      }
    }
    .courseProgress {
      @include dashboardSidebar();
      h1 {
        @include customH1();
      }
      .progress {
        @include customFlex(column, space-between, stretch);
        // max-height: 80vh;
        // overflow-y: auto;

        .progress-item {
          padding: 0.5rem;
          @include customFlex(column, space-between, stretch);

          strong {
            font-weight: $font-regular;
            padding: 0.25rem 0;
          }

          p {
            margin: 0;
            color: #0373ba;
            padding: 0.25rem 0;
          }
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #0373ba;
    border-radius: 0.75rem;
    background-color: #0373ba;
  }

  ::-webkit-scrollbar-thumb {
    background: #ffffff;
    box-shadow: inset 0 0 5px #ffffff;
    border-radius: 0.75rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #fffffff3;
  }
}
