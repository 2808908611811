.main-user-dash{
    width: -webkit-fill-available;
    display: flex;
    justify-content: center;
    text-align: center;
}
.all-user-strip{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2% 0 1.5% 0;
    border-radius: 5px;
    padding: 1rem;
    // border: 2px solid black;
    height: 3.5rem;
    background: white;
    box-shadow: 4px 4px 20px 8px rgba(0, 0, 0, 0.08);
}
.userGrid{
    // height: calc(100% - 6rem);
    height: 75%;
    // background: white;
    margin-top: 1.5%;
}
.userTable{
    // height: calc(100% - 6rem);
    height: 75%;
    background: white;
    margin-top: 1.5%;
    box-shadow: 4px 4px 20px 8px rgba(0, 0, 0, 0.08);
}