.name{
    font-weight: bold;
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: 1.3rem;
}
.readMore{
    padding-bottom: 5%;
}
.dept{
    color: #444;
}
.dept-data{
    font-weight: bold;
}