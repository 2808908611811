@use "../../../scss" as *;

.savedReading {
  margin-bottom: 1rem;

  .content {
    max-height: 30vh;
    overflow-y: auto;
    padding: 0.5rem 0;

    .card {
      @include dashboardCard();

      div:nth-child(1) {
        @include customFlex(row, flex-start, center);

        div:nth-child(1),
        div:nth-child(2) {
          padding: 0 0.25rem;
        }
      }

      .category {
        position: absolute;
        background-color: $highlight-color;
        padding: 0.25rem 0.75rem;
        border-radius: 0.5rem;
        right: 10%;
        top: -5%;
      }
    }
  }
}

.savedCourse {
  margin-bottom: 1rem;
  .content {
    max-height: 30vh;
    overflow-y: auto;
    padding: 0.5rem 0;

    .card {
      @include dashboardCard();
      div:nth-child(1) {
        @include customFlex(row, flex-start, center);

        div:nth-child(1),
        div:nth-child(2) {
          padding: 0 0.25rem;
        }
      }
      div:nth-child(2) {
        @include customFlex(row, flex-end, center);

        button {
          margin: 0 0.5rem;
        }
      }
    }
  }
  .noData {
    @include customFlex($flex-direction: column);
    button {
      margin: 1rem 0;
    }
  }
}

.dashboardVacancies {
  @include dashboardSidebar();
  h1 {
    @include customH1();
  }
  .content {
    margin-top: 0.5rem;
    max-height: 80vh;
    overflow-y: auto;

    ul {
      margin: 0;
      padding: 0;
      padding-left: 1.25rem;
    }
  }
}
