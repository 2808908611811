@use "../../scss" as *;

.contactusHero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--grid_gap);

  h1,
  p {
    margin: var(--section_margin);
  }

  @media all and (max-width: $screen-md) {
    grid-template-columns: 1fr;
  }

  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    font-family: $font-family-1;

    #Phone,
    #Message,
    #checkbox,
    #button {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    fieldset {
      border: none;
    }
  }
  .address {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-self: center;

    .address-card {
      grid-column: 2/5;
      box-shadow: 0px 25px 50px -12px rgb(0 0 0 / 7%);
      background: $tertiary-color;
      border-radius: 8px;
      padding: 1rem 2rem;
      z-index: 2;
      position: relative;

      @media all and (max-width: $screen-md) {
        grid-column: 1/6;
        margin: 0 1rem;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        h3 {
          margin-bottom: 0.75rem;
          padding: 0;
          color: #0053c2;
          font-weight: $font-regular;
        }
        p {
          margin: 5px 0;
        }
      }
      .social-icons {
        li {
          display: inline;
          padding: 5px;
          cursor: pointer;
        }
        img {
          padding: 0.25rem;
          background: #00d5f124;
          color: white;
          border-radius: 0.25rem;
        }
      }
    }
    .icon-1 {
      position: relative;
      left: 65%;
      top: 75%;
      z-index: 1;

      @media all and (max-width: $screen-md) {
        display: none;
      }
    }
    .icon-2 {
      position: relative;
      right: 15%;
      top: 10%;
      z-index: 1;

      @media all and (max-width: $screen-md) {
        display: none;
      }
    }
  }
}
.location {
  width: 100%;
  margin: 2rem auto;

  h1,
  p {
    margin: var(--section_margin);
  }
}
