.nav ul {
  list-style: none;
}
.nav{
  padding: 1rem 1.5rem;
  color: white;
  font-family: "Lato";
  /* border: 2px solid yellow; */
  background: #0373BA;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sec-2 ul{
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 2rem;
}
.sec-1 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
